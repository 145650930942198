var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container difference" }, [
    _c("div", { staticClass: "actions_part clearfix" }, [
      _c("div", { staticClass: "actions_wrap" }, [
        _c(
          "div",
          { staticClass: "display_ib mr10 mb10" },
          [
            _c("span", { staticClass: "tag" }, [_vm._v("差异类型：")]),
            _c(
              "el-select",
              {
                staticClass: "select_single w200 mr10",
                attrs: { placeholder: "请选择差异类型" },
                on: { change: _vm.abnormalTypeSector },
                model: {
                  value: _vm.differenceType,
                  callback: function($$v) {
                    _vm.differenceType = $$v
                  },
                  expression: "differenceType"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "全部", value: "" }
                }),
                _vm._l(_vm.abnormalList, function(item) {
                  return _c("el-option", {
                    key: item.index,
                    attrs: { label: item.name, value: item.index }
                  })
                })
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "display_ib mr10 mb10" },
          [
            _c("span", { staticClass: "tag" }, [_vm._v("预收货编号：")]),
            _c("el-input", {
              staticClass: "input_single w200 mr10",
              attrs: { placeholder: "请输入" },
              model: {
                value: _vm.prepareReceiptCode,
                callback: function($$v) {
                  _vm.prepareReceiptCode = $$v
                },
                expression: "prepareReceiptCode"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "display_ib mr10 mb10" },
          [
            _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
            _c(
              "el-select",
              {
                staticClass: "select_single w200 mr10",
                attrs: { filterable: "", placeholder: "请选择" },
                on: { change: _vm.searchHandle },
                model: {
                  value: _vm.supplierCode,
                  callback: function($$v) {
                    _vm.supplierCode = $$v
                  },
                  expression: "supplierCode"
                }
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { label: "全部", value: "" }
                }),
                _vm._l(_vm.supplierList, function(item) {
                  return _c("el-option", {
                    key: item.supplierId,
                    attrs: { label: item.supplierCode, value: item.supplierId }
                  })
                })
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "display_ib mr10 mb10" },
          [
            _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
            _c("el-input", {
              staticClass: "input_single w200 mr10",
              attrs: { placeholder: "请输入" },
              model: {
                value: _vm.sourceMaterialCode,
                callback: function($$v) {
                  _vm.sourceMaterialCode = $$v
                },
                expression: "sourceMaterialCode"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "actions_btn_wrap down t_right" },
        [
          _c("el-button", {
            attrs: {
              size: "small",
              type: "primary",
              icon: "el-icon-search",
              circle: "",
              title: "搜索"
            },
            on: { click: _vm.searchHandle }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "table_part clearfix" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "subnav_wrap",
            on: { "tab-click": _vm.switchTabHandle },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "到货差异明细", name: "All" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading"
                      }
                    ],
                    ref: "multipleTable",
                    staticClass: "table_list",
                    attrs: {
                      data: _vm.exceptionList,
                      "element-loading-text": "拼命加载中",
                      "row-key": "id",
                      "expand-row-keys": _vm.expands,
                      border: "",
                      fit: "",
                      stripe: ""
                    },
                    on: {
                      "row-click": _vm.rowClick,
                      "expand-change": _vm.rowClick
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.childListLoading,
                                      expression: "childListLoading"
                                    }
                                  ],
                                  ref: "childMultipleTable_" + scope.row.id,
                                  staticClass: "table_list",
                                  attrs: {
                                    data: scope.row.ehiList,
                                    "element-loading-text": "拼命加载中",
                                    border: "",
                                    fit: ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { fixed: "", label: "行号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.sn) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "供应商名称" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.supplierName) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "物料编号",
                                      "min-width": "180",
                                      "class-name": "marpno_wuliao",
                                      "show-overflow-tooltip": true
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.sourceMaterialCode
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "产品编号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.productMaterialCode
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "确认书编号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.confirmNumbers) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "差异类型" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.differenceType) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "工厂包号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.factoryBatchNumber
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "发票数量" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.batchInvoiceNumber
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "入库差异单号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.inStockExCode) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "入库物料编号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.inStockMaterialCode
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "实际工厂包号" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    row.factoryBatchNumber
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "入库数量" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.inStockQuantity) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "差异入库时间" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(row.inStockTime) +
                                                  "\n                    "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "预收货清单编号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.prepareReceiptCode) +
                                  "\n              "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination_wrap fr" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": [10, 50],
                "page-size": _vm.pageSize,
                layout: "sizes, total, prev, pager, next",
                total: _vm.total
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
                "update:currentPage": function($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPage = $event
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }